.banner-image-o-s {
  background-image: url('../../../assets/images/schools/orangefigs-iis-banner-image.png');
  height: 940px;
  background-size: cover;
  /* background-position: center; */
  background-repeat: no-repeat;
  margin-bottom: 80px;
}
body {
  font-family: 'Kulim Park', sans-serif;
}
.nopadding {
  padding: 0px;
}
.sectionOne {
  position: relative;
}
.sectionTwo {
  /* margin:0px 90px;   */
  margin-bottom: -90px;
  width: calc(100vw - 180px);
  background: #b52a65;
  box-shadow: 0px 4px 34px rgba(181, 42, 101, 0.3);
  border-radius: 10px;
  height: 350px;
  position: absolute;
  bottom: -220px;
  max-width: 1260px;
  left: 50%;
  transform: translate(-50%);
}
.text-right {
  text-align: right;
}
.sectionTwo h4 {
  font-weight: 600;
  font-size: 26px;
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #f9f9f9;
  padding: 60px 0px 40px 0px;
}
.sectionTwo form {
  padding: 0px 70px;
}
.sectionTwo .form-input input {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  background: none;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  /* text-align: center; */
  letter-spacing: 3px;
  /* text-transform: uppercase; */
  width: 100%;
  margin-bottom: 20px;
  height: 60px;
  padding: 20px;
}
.sectionTwo .form-input input::placeholder {
  color: #fff;
}
.sectionTwo .form-input button {
  background: #f7f7f7;
  border-radius: 10px;
  height: 60px;
  width: 100%;
  font-weight: 600;
  font-size: 24px;
  /* identical to box height, or 125% */
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #b52a65;
  border: none;
}
.sectionThree-o {
  padding: 50px 250px 80px 250px ;
  /* margin-top: 290px; */
  background: #faf7f2;
}

.sectionThree-o h5 {
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: none !important;
  font-style: normal;
  color: #b52a65;
}
.sectionThree-o h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 64px;
  text-align: center;
  letter-spacing: -2px;
  color: #b52a65;
}
.sectionThree-o p {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #333333;
  padding-top: 10px;
}

.sectionFour {
  padding: 0px 150px 90px 150px;
  background: #faf7f2;
}
.sectionFour .title {
  font-family: Kulim Park !important;
}

.sectionFour .sub-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  font-family: Kulim Park !important;
  color: #b52a65;
}
.sectionFour p {
  padding-top: 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #333333;
  font-family: Andes !important;
  margin-bottom: 0px;
}
.sectionFive {
  padding: 84px;
  background: rgb(250, 247, 242, 0.5);
}
.sectionFive .containeDiv {
  margin: 0px;
  padding: 0px;
  width: 100%;
}
.sectionFive .sub-title {
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #fbab52;
}
.sectionFive .title {
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 64px;
  /* or 114% */
  letter-spacing: -2px;
  color: #b52a65;
}
.sectionFive p {
  font-style: normal;
  font-weight: 600;
  padding-top: 10px;
  font-size: 20px;
  line-height: 26px;
  color: #333333;
}
.sectionFive img {
  max-width: 100%;
}
.d-flex {
  align-items: center;
  display: flex;
}

.d-flex a {
  padding: 0px 20px 0px 0px;
}
.sectionSix {
  background: #f6efe6;
  padding: 90px;
  position: relative;
}
.sectionSix .containeDiv {
  margin: 0px;
  padding: 0px;
  width: 100%;
}
.sectionSix .title {
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  margin-bottom: 28px;
  line-height: 64px;
  /* or 114% */
  letter-spacing: -2px;
  color: #b52a65;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sectionSix p {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #333333;
  margin-bottom: 14px;
}
.sectionSix .price {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #b52a65;
  margin-bottom: 10px;
}
.sectionSix .section-background {
  position: absolute;
  left: -10px;
  top: 0px;
  z-index: 1;
}
.sectionSix .price_label {
  color: #b52a65;
}
.sectionSeven {
  /* margin: 40px 120px; */
  /* min-height: 260px;  */
  padding: 84px 90px;
  min-height: 540px;
  background: #faf7f2;
}
.sectionSix button {
  width: 360px;
  height: 72px;
  background: #a53862;
  border-radius: 20px;
  font-weight: 600;
  font-size: 26px;
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #fbfafb;
  border: none;
  margin: 30px 0px;
}
.sectionSeven h4 {
  font-weight: 600;
  font-size: 56px;
  line-height: 64px;
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #a53862;
  /* max-width: 600px; */
  margin: 0 auto;
  margin-bottom: 30px;
}
.sectionSeven .text {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  /* or 143% */
  text-align: center;
  color: #333333;
  margin-bottom: 80px;
}

.sectionSeven .text1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 20px;
  text-align: center;
  color: #333333;
  margin-bottom: 30px;
}

.sectionSeven .text2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #FBAB52;
  text-transform: uppercase;
  /* margin-bottom: 80px; */
}
.sectionSeven button {
  width: 360px;
  height: 72px;
  background: #a53862;
  border-radius: 20px;
  font-weight: 600;
  font-size: 26px;
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #fbfafb;
  border: none;
  margin: 30px 0px;
}
.sectionSeven h6 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 32px;
  text-align: center;
  color: #b52a65;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-top: 60px;
}
.sectionEight {
  position: relative;
}
.sectionEight .BGTop img {
  object-fit: cover;
  width: 100%;
}
.sectionEight .BGBottom img {
  position: absolute;
  bottom: 0px;
  object-fit: cover;
  width: 100%;
}
.sectionEight .content-left img {
  position: absolute;
  top: 0px;
  max-width: 100%;
  left: 90px;
}
.sectionEight .content-right {
  max-width: 450px;
  text-align: left;
  position: absolute;
  right: 80px;
  top: 50%;
  transform: translate(0px, -50%);
}

img {
  max-width: 100%;
}
.mtop-50 {
  margin-top: 50px;
}
.mtop-50 img {
  margin-right: 10px;
}
.sectionEight h6 {
  font-weight: 600;
  font-size: 26px;
  line-height: 40px;
  font-family: Kulim Park;
  /* text-align: center; */
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #fbab52;
}
.sectionEight h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  font-family: Kulim Park;
  line-height: 64px;
  letter-spacing: -2px;
  color: #b52a65;
}
.sectionEight {
  height: 789px;
  background-size: cover;
  background-repeat: no-repeat;
}
.sectionEight p {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  padding-top: 10px;
  line-height: 26px;
  font-family: Andes;
  color: #333333;
}
.footer {
  height: 85px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  color: #fbfafb;
  background: #a53862;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 90px;
}
button:focus {
  outline: none !important;
}
.footer p {
  margin-right: 35px;
}
.footer ul {
  display: inline-flex;
}
.footer ul li {
  margin-right: 35px;
  color: #fff !important;
  text-decoration: none;
}
.footer .noList {
  list-style-type: none;
}

.footer a {
  text-decoration: none;
}
input:focus {
  outline: none !important;
}

@media only screen and (min-width: 1000px) and (max-width: 1200px) {
  .sectionEight .content-left img {
    max-width: 50%;
    top: 100px;
    left: 0px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .sectionEight .content-left img {
    max-width: 50%;
    top: 100px;
    left: 150px;
  }
  .sectionEight .content-right {
    max-width: 450px;
    text-align: left;
    position: absolute;
    right: 181px;
    top: 50%;
    transform: translate(0px, -50%);
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1900px) {
  .sectionEight .content-left img {
    max-width: 50%;
    top: 100px;
    left: 200px;
  }
  .sectionEight .content-right {
    max-width: 450px;
    text-align: left;
    position: absolute;
    right: 300px;
    top: 50%;
    transform: translate(0px, -50%);
  }
}

@media only screen and (min-width: 1900px) and (max-width: 2500px) {
  .sectionEight .content-left img {
    max-width: 50%;
    top: 100px;
    left: 250px;
  }
  .sectionEight .content-right {
    max-width: 450px;
    text-align: left;
    position: absolute;
    right: 350px;
    top: 50%;
    transform: translate(0px, -50%);
  }
}
@media only screen and (min-width: 470px) and (max-width: 530px) {
  .sectionEight .content-left img {
    /* top : 67%; */
    max-width: 80% !important;
  }
}
@media only screen and (min-width: 530px) and (max-width: 700px) {
  .sectionEight .content-left img {
    /* top : 67%; */
    max-width: 50% !important;
  }
}
@media only screen and (min-width: 700px) and (max-width: 900px) {
  .sectionEight .content-left img {
    /* top : 67%; */
    /* max-width: 50%!important; */
    top: -90px !important;
    max-width: 44% !important;
  }
  .sectionEight .content-right {
    left: 50% !important;
    transform: translate(-50%);
    top: 50% !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .sectionEight .content-left img {
    left: 25%;
    margin-top: 10%;
    top: 60px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1024px) {
  .sectionEight .content-left img {
    left: 0;
    margin-top: 10%;
    top: 60px;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1000px) {
  .sectionEight .content-left img {
    max-width: 35% !important;
    top: 200px;
    left: 50px;
  }
}
@media only screen and (max-width: 850px) {
  .sectionEight .content-left img {
    left: 20px;
    right: 20px;
    max-width: 90%;
    margin: 0 auto;
    margin-top: 15%;
    text-align: center;
    top: -40px;
  }
  .sectionEight .content-right {
    left: 20px;
    right: 0px;
    bottom: 0px;
    top: 65%;
  }
}

@media only screen and (max-width: 1024px) {
  .title {
    font-size: 30px !important;
    line-height: 45px !important;
  }
  .sectionEight {
    height: 900px !important;
  }
}
@media only screen and (max-width: 767px) {
  body,
  html {
    overflow-x: hidden !important;
  }

  .sectionTwo .form-input button {
    font-weight: 600;
    font-size: 20px;
  }
  .sectionSix {
    padding: 40px;
  }
  .sectionSix button {
    width: 100%;
    font-size: 20px;
    font-weight: 600;
  }

  .sectionSeven button {
    width: 100%;
    font-size: 20px;
    font-weight: 600;
  }
  .main-head {
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 26px !important;
    letter-spacing: 3px !important;
  }
  .banner-image-o-s {
    height: 270px;
  }
  .sectionThree-o {
    padding: 50px 40px 40px 40px ;
    /* margin-top: 290px; */
    /* background: #FAF7F2; */
  }

  .sectionSix .section-background {
    height: 50%;
    top: -20px;
  }
  .testimonial-left-content,
  .price {
    text-align: center;
  }
  .content-right .d-flex {
    flex-direction: row !important;
    justify-content: center;
  }
  .sectionEight {
    height: 900px;
  }

  .sectionSeven {
    padding: 40px 40px;
  }
  .sectionSeven h5 {
    font-size: 18px;
    line-height: 34px;
    margin-bottom: 30px;
  }
  .sectionFour p,
  .sectionSeven h6 {
    margin-bottom: 30px;
    font-size: 24px !important;
    line-height: 30px !important;
    text-align: center;
    color: #b52a65;
    max-width: 220px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .sectionFive p {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #333333;
  }
  .sectionThree-o h5 {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: none !important;
    font-style: normal;
    color: #b52a65;
  }
  .sectionThree-o p {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */
    text-align: center;
    color: #333333;
  }
  .text {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-align: center;
    color: #333333;
  }
  .sectionTwo {
    height: 410px;
    position: absolute;
    bottom: -350px;
    /* margin: 0px 40px; */
    width: calc(100vw - 80px);
  }
  .sub-title {
    font-weight: bold !important;
    font-size: 20px !important;
    line-height: 32px !important;
    /* identical to box height, or 160% */
    font-family: Kulim Park !important;
    text-align: center;
    color: #b52a65;
  }
  input {
    font-size: 16px !important;
  }
  .sectionSeven h4 {
    font-size: 28px !important;
    line-height: 34px !important;
  }
  .sectionTwo h4 {
    padding: 10px;
    font-size: 20px;
  }
  .sectionFour {
    padding: 0px 20px 40px 20px;
  }
  .sectionTwo form {
    padding: 0px;
  }
  .d-flex {
    flex-direction: column;
  }
  .sectionFive {
    padding: 40px 40px;
  }
  .footer {
    flex-direction: column;
    height: auto;
    padding: 0px;
  }
  /* .footer ul{
        padding-left: 8px;
        margin-bottom: 0px;
       
    } */
  .footer ul {
    padding-left: 8px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
  }
  .footer p {
    margin-bottom: 0px;
  }
  .footer ul li {
    margin-bottom: 10px;
  }
  .title {
    font-size: 28px !important;
    line-height: 34px !important;
    text-align: center;
  }
  .sectionEight p {
    font-weight: 600;
    font-size: 14px !important;
    line-height: 20px !important;
  }
}

.videoComponent {
  width: initial;
  height: min-content;
}
.footer a:hover {
  text-decoration: none;
  cursor: pointer;
}
.disabled-link {
  pointer-events: none;
  text-decoration: none;
  color: #535b65 !important;
  font-size: 21px !important;
  line-height: 24px !important;
  text-transform: none !important;
  padding: 0 0 10px !important;
}

/* carousel itenms
 */

.row {
  margin: 0px;
}
.carousel-section {
  padding: 84px 84px 150px 84px !important;
}
.carousel .subPara {
  /* background: rgba(181, 42, 101, 0.5);   */
  background: #b52a65;
  color: #fff;
  padding: 15px;
  position: absolute;
  /* height: 15vh; */
  bottom: 0px;
  width: 80%;
  margin-right: 15px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

#owl-demo .item {
  margin: 3px;
}
#owl-demo .item img {
  display: block;
  width: 100%;
  height: auto;
}
.carousel .subPara h6 {
  font-weight: bold;
  font-size: 28px;
  margin: 0px;
  line-height: 35px;
  letter-spacing: -1.10688px;
  color: #ffffff;
}
.carousel img {
  /* margin-top:-20%; */
  width: 100%;
  border-radius: 10px;
}
.carousel-inner {
  margin-top: -40px;
  margin-bottom: 40px;
}
.carousel .subPara span {
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 2.61503px;
  text-transform: uppercase;
  color: #fbab52;
}
.carousel .subPara p {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #f6efe6;
  text-overflow: ellipsis;
}

.clamplines_para {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #f6efe6;
  text-overflow: ellipsis;
}

.carousel .col-xs-4,
.carousel .col-md-4 {
  position: relative;
  height: 420px;
}
.carousel-control.left {
  background-image: none !important;
  width: 30px;
  height: 30px;
  /* left: 1253.25px; */
  /* top: 3557.23px; */
  background: #b52a65;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  bottom: -70px;
  top: auto;
  left: 92%;
}
.carousel-control.left img,
.carousel-control.right img {
  width: 9px;
  height: 13px;
}
.carousel-control.right {
  background-image: none !important;
  width: 30px;
  height: 30px;
  background: #edcea6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  bottom: -70px;
  top: auto;
  left: 96%;
}
.carousel-indicators {
  bottom: -40px;
  left: 95%;
  width: auto !important;
  margin-left: -4%;
}
.carousel-indicators .active {
  background: #b52a65 !important;
  border: 2px solid #b52a65 !important;
}
.carousel-indicators li {
  width: 40px !important;
  height: 0px !important;
  border: 2px solid #edcea6 !important;
}

.rowCounter {
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  padding: 20px;
  font-size: 28px;
  line-height: 64px;
  letter-spacing: -2px;
  color: #b52a65;
}

.item {
  border-radius: 10px;
}

.carousel-title {
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 64px;
  text-align: center;
  letter-spacing: -2px;
  color: #b52a65;
}
/* 
.subPara h6{
    opacity: 1  !important;
    color: black  !important;
} */

.itemsize {
  max-width: 80% !important;
  margin-left: 10%;
}

/* .img_hover{
    opacity: 0.6;
} */

.backcolor {
  background: rgb(250, 247, 242, 0.5) !important;
  margin-top: 0 !important;
  padding-top: 50px;
}

@media only screen and (min-width: 900px) {
  .itemsize {
    max-width: 80% !important;
    margin-left: 10%;
    max-height: 30em;
    overflow: -moz-hidden-unscrollable;
    overflow: hidden;
    border-radius: 10px;
  }

  .carousel .subPara {
    background: rgba(181, 42, 101);
    color: #fff;
    padding: 15px;
    position: absolute;
    /* height: 15vh; */
    bottom: 0px;
    width: 80%;
    margin-right: 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.clamp-lines__button {
  background: none;
  border: 0;
  color: white;
  cursor: pointer;
  padding: 0;
  font-weight: bold;
}

.react-multiple-carousel__arrow--right {
  right: 0 !important;
}

.react-multiple-carousel__arrow--left {
  left: 0 !important;
}

.carousel img {
  margin-top: -20%;
  width: 100%;
  border-radius: 10px;
}

.itemsize {
  overflow: -moz-hidden-unscrollable;
  overflow: hidden;
  border-radius: 10px;
}

@media only screen and (max-width: 700px) {
  .carousel .subPara h6 {
    font-weight: bold;
    font-size: 18px;
    margin: 0px;
    line-height: 20px;
    letter-spacing: -1.10688px;
    color: #ffffff;
  }
  .clamplines_para {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #f6efe6;
    text-overflow: ellipsis;
  }
}
.dots {
  text-align: center;
  /* padding: 2% 0; */
}

.regular {
  height: 20px !important;
  width: 20px;
  background: #bd2967;
  border-radius: 50%;
  margin: 10px;
}

.slected {
  height: 20px !important;
  width: 20px;
  background: #b3768f;
  border-radius: 50%;
  margin: 10px;
}

.dots ul {
  /* display: table; */
  list-style-type: none;
  overflow: hidden;
  display: table;
  margin: 0 auto;
  padding: 0;
  text-align: left;
}

.dots li {
  float: left;
  display: inline;
  /* margin-left: 50%; */
}

/* .dots li div {
    display: block;
  } */
.price-tag {
  text-align: center;
  /* margin-bottom: 50px; */
  background: #f7f7f7;
  /* padding-top: 20px; */
  /* padding-bottom: 60px; */
}